<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title class="d-flex justify-space-between border-bottom">
				<span class="subtitle-1">Добавление въезда</span>
				<v-btn color="dark" @click="closeModal" icon>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text v-else>
				<v-container>
					<v-form ref="form">
						<v-row>
							<!-- Название въезда -->
							<v-col cols="12" sm="6">
								<span class="subtitle-2 font-weight-light">
									Название въезда
								</span>
								<TextField v-model="data.name"></TextField>
							</v-col>
							<!-- Наименование сети -->
							<v-col cols="12" sm="6">
								<span class="subtitle-2 font-weight-light">
									Тип въезда
								</span>
								<Select
									:value.sync="data.entry_type_id"
									:items="catalog.entrances_types"
									item-text="name"
									item-value="id"
								></Select>
							</v-col>
							<!-- Наименование сети -->
							<v-col cols="12" sm="6">
								<span class="subtitle-2 font-weight-light">
									Наименование сети
								</span>
								<TextField v-model="data.wifi_name"></TextField>
							</v-col>
							<!-- Пароль сети -->
							<v-col cols="12" sm="6">
								<span class="subtitle-2 font-weight-light">
									Пароль сети
								</span>
								<TextField v-model="data.wifi_password"></TextField>
							</v-col>
							<!-- Дома для шлагбаума -->
							<v-col v-if="data.entry_type_id === entryId" cols="12">
								<span class="subtitle-2 font-weight-light">
									Список адресов
								</span>
								<Autocomplete
									v-model="data.houses_ids"
									multiple
									small-chips
									clearable
									deletable-chips
									dense
									item-text="apartment_address"
									item-value="_id"
									:items="houses"
								></Autocomplete>
							</v-col>
							<!-- Дом для паркинга -->
							<v-col v-if="data.entry_type_id === parkingId" cols="12">
								<span class="subtitle-2 font-weight-light">
									Адрес
								</span>
								<Autocomplete
									v-model="data.house_id"
									clearable
									dense
									item-text="apartment_address"
									item-value="_id"
									:items="houses"
								></Autocomplete>
							</v-col>
							<!-- Alert -->
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
			<!-- Footer -->
			<v-card-actions class="pt-0 pb-5 px-8 d-flex justify-end">
				<!-- Footer создания -->
				<v-btn
					v-if="!toEdit"
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isAdding"
					@click="addEntry"
				>
					Добавить
				</v-btn>
				<!-- Footer редактирования -->
				<div v-else class="d-flex">
					<v-btn
						class="subtitle-2 text-capitalize mr-2"
						color="red white--text"
						depressed
						:loading="isDeleting"
						@click="showModalConfirm = true"
					>
						Удалить
					</v-btn>
					<v-btn
						class="subtitle-2 text-capitalize"
						color="primary"
						depressed
						:loading="isEditing"
						@click="editEntry"
					>
						Сохранить
					</v-btn>
				</div>
			</v-card-actions>
		</v-card>
		<!-- Подтверждение удаления -->
		<ModalConfirm
			v-if="showModalConfirm"
			title="Удаление въезда"
			:text="`Удалить ${toEdit.name}?`"
			@close-modal="deleteEntry"
		/>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

import ModalConfirm from "@/components/app/ModalConfirm";

export default {
	name: "EntriesTableModal",
	components: {
		ModalConfirm,
	},
	props: {
		toEdit: {
			type: Object,
			default: undefined,
		},
	},
	data() {
		return {
			alert: null,
			data: {
				name: "",
				entry_type_id: "",
				wifi_name: "qrcom",
				wifi_password: "0411",
				houses_ids: [],
				house_id: null,
			},
			dialog: true,
			loading: true,
			isAdding: false,
			isDeleting: false,
			isEditing: false,
			showModalConfirm: false,
		};
	},

	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
		}),
		entryId() {
			return this.catalog.entrances_types.find(e => e.sys_name === "entry").id;
		},
		parkingId() {
			return this.catalog.entrances_types.find(e => e.sys_name === "parking")
				.id;
		},
	},

	watch: {
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 3000);
		},
	},
	methods: {
		// Валидация
		customValidation() {
			let message;

			if (!this.data.name) {
				message = "Укажите название въезда";
			} else if (!this.data.entry_type_id) {
				message = "Укажите тип въезда";
			} else if (!this.data.wifi_name) {
				message = "Укажите название сети";
			} else if (!this.data.wifi_password) {
				message = "Укажите пароль сети";
			} else if (
				this.data.entry_type_id === this.entryId &&
				!this.data.houses_ids.length
			) {
				message = "Укажите дома";
			} else if (
				this.data.entry_type_id === this.parkingId &&
				!this.data.house_id
			) {
				message = "Укажите адрес";
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
				return false;
			}

			return true;
		},
		// Добавление въезда
		async addEntry() {
			this.alert = null;

			if (!this.customValidation()) return;

			this.isAdding = true;

			try {
				let res;

				res = await this.$api.ksk.add_entry(this.data);

				this.alert = {
					color: "success",
					message: res.success || "Заявка успешно создана!",
				};

				this.$refs.form.resetValidation();
				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAdding = false;
			}
		},
		// Удаление въезда
		async deleteEntry(isConfirmed) {
			this.showModalConfirm = false;

			if (!isConfirmed) return;

			try {
				this.isDeleting = true;

				const res = await this.$api.ksk.delete_entry(this.toEdit._id);

				this.alert = {
					color: "success",
					message: res.success || "Въезд удален",
				};

				this.$emit("update-info");

				setTimeout(() => this.closeModal(), 500);
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.isDeleting = false;
			}
		},

		// Редактирование въезда
		async editEntry() {
			try {
				this.isEditing = true;

				const res = await this.$api.ksk.edit_entry(this.toEdit._id, this.data);

				this.alert = {
					color: "success",
					message: res.success || "Въезд удален",
				};

				this.$emit("update-info");
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Ошибка",
				};
			} finally {
				this.isEditing = false;
			}
		},

		closeModal() {
			this.dialog = false;
			this.$emit("close-modal");
		},
	},

	async created() {
		try {
			this.loading = true;
			if (this.toEdit) {
				this.data = { ...this.toEdit };
			}
		} catch (error) {
			console.error(error);
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
